/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@font-face {
  font-family: "Metropolis";
  font-weight: 100;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Thin.eot");
  src: local("Metropolis Thin"), url("/assets/fonts/EOT/Metropolis-Thin.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Thin.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Thin.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Thin.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 100;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-ThinItalic.eot");
  src: local("Metropolis Thin Italic"), url("/assets/fonts/EOT/Metropolis-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-ThinItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-ThinItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-ThinItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 200;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-ExtraLight.eot");
  src: local("Metropolis Extra Light"), url("/assets/fonts/EOT/Metropolis-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-ExtraLight.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-ExtraLight.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-ExtraLight.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 200;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-ExtraLightItalic.eot");
  src: local("Metropolis Extra Light Italic"), url("/assets/fonts/EOT/Metropolis-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-ExtraLightItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-ExtraLightItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-ExtraLightItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Light.eot");
  src: local("Metropolis Light"), url("/assets/fonts/EOT/Metropolis-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Light.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Light.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-LightItalic.eot");
  src: local("Metropolis Light Italic"), url("/assets/fonts/EOT/Metropolis-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-LightItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-LightItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-LightItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Regular.eot");
  src: local("Metropolis Regular"), url("/assets/fonts/EOT/Metropolis-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Regular.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Regular.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-RegularItalic.eot");
  src: local("Metropolis Regular Italic"), url("/assets/fonts/EOT/Metropolis-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-RegularItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-RegularItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-RegularItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Medium.eot");
  src: local("Metropolis Medium"), url("/assets/fonts/EOT/Metropolis-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Medium.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Medium.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-MediumItalic.eot");
  src: local("Metropolis Medium Italic"), url("/assets/fonts/EOT/Metropolis-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-MediumItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-MediumItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-MediumItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-SemiBold.eot");
  src: local("Metropolis Semi Bold"), url("/assets/fonts/EOT/Metropolis-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-SemiBold.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-SemiBold.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 600;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-SemiBoldItalic.eot");
  src: local("Metropolis Semi Bold Italic"), url("/assets/fonts/EOT/Metropolis-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-SemiBoldItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-SemiBoldItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-SemiBoldItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Bold.eot");
  src: local("Metropolis Bold"), url("/assets/fonts/EOT/Metropolis-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Bold.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Bold.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-BoldItalic.eot");
  src: local("Metropolis Bold Italic"), url("/assets/fonts/EOT/Metropolis-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-BoldItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-BoldItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-BoldItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 800;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-ExtraBold.eot");
  src: local("Metropolis Extra Bold"), url("/assets/fonts/EOT/Metropolis-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-ExtraBold.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-ExtraBold.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 800;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-ExtraBoldItalic.eot");
  src: local("Metropolis Extra Bold Italic"), url("/assets/fonts/EOT/Metropolis-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-ExtraBoldItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-ExtraBoldItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-ExtraBoldItalic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 900;
  font-style: normal;
  src: url("/assets/fonts/EOT/Metropolis-Black.eot");
  src: local("Metropolis Black"), url("/assets/fonts/EOT/Metropolis-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-Black.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-Black.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-Black.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  font-weight: 900;
  font-style: italic;
  src: url("/assets/fonts/EOT/Metropolis-BlackItalic.eot");
  src: local("Metropolis Black Italic"), url("/assets/fonts/EOT/Metropolis-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/WOFF2/Metropolis-BlackItalic.woff2") format("woff2"), url("/assets/fonts/WOFF/Metropolis-BlackItalic.woff") format("woff"), url("/assets/fonts/TTF/Metropolis-BlackItalic.ttf") format("truetype");
  font-display: swap;
}
.cookie-banner {
  background-color: rgba(80, 17, 81, 0.6) !important;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
html,
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
}
.snowfall {
  z-index: 9999999;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: hsl(210, 15%, 20%);
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsl(210, 14%, 66%);
  border: 2px solid hsl(210, 15%, 20%);
}
footer {
  color: white !important;
  background-color: black !important;
  background: black !important;
}
.footer-media {
  float: left;
  vertical-align: middle;
}
.footer-media > a {
  padding: 0.2em;
  font-size: 1.5em;
  color: white;
}
.footer-media > a:hover,
.footer-media > a:active,
.footer-media > a:focus {
  color: violet !important;
}
.content {
  z-index: 50;
}
@media (max-width: 720px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: inherit;
  }
}
@media (min-width: 720px) {
  .desktop-only {
    display: inherit;
  }
  .mobile-only {
    display: none;
  }
}

